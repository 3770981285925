<section>
  <header>
    <h5>{{ 'signin.welcome' | translate }}</h5>
  </header>

  <div class="tabs">
    <button [class.active]="activeTab==='login'" (click)="setTabValue('login')">{{ 'signin.signin' | translate }}
    </button>
    <button [class.active]="activeTab==='register'"
            (click)="setTabValue('register')">{{ 'signin.new_account' | translate }}
    </button>
    <!--    <button [class.active]="activeTab==='forgot'" (click)="setTabValue('forgot')">{{'signin.forgot_password' | translate}}</button>-->
  </div>

  <ng-container *ngIf="activeTab==='register'">
    <ng-container *ngTemplateOutlet="registerForm"></ng-container>
  </ng-container>
  <ng-container *ngIf="activeTab==='login'">
    <ng-container *ngTemplateOutlet="signInForm"></ng-container>
  </ng-container>
  <ng-container *ngIf="activeTab==='forgot'">
    <ng-container *ngTemplateOutlet="forgotPasswordForm"></ng-container>
  </ng-container>

  <!-- Uncomment when we will implement it -->
  <!--  <ng-container *ngIf="!environment.production">-->
  <!--    <hr>-->
  <!--    <footer>-->
  <!--      <p>{{'signin.use_oauth' | translate}}</p>-->
  <!--      <asl-google-signin-button type='standard' size='large'></asl-google-signin-button>-->
  <!--    </footer>-->
  <!--  </ng-container>-->
</section>


<ng-template #registerForm>
  <article [formGroup]="form">
    <label>
      <span>{{ 'signin.email' | translate }}</span>
      <input type="email" placeholder="{{'signin.email_placeholder' | translate}}" formControlName="email">
      <div
        *ngIf="form.controls.email.invalid && (form.controls.email.dirty || form.controls.email.touched)"
        class="error"
      >
        <div *ngIf="form.controls.email.errors.email">
          {{ 'signin.email_invalid' | translate }}
        </div>
      </div>
    </label>
    <label>
      <span>{{ 'signin.password' | translate }}</span>

      <input type="password" placeholder="{{'signin.new_password_placeholder' | translate}}" formControlName="password">
      <div
        *ngIf="form.controls.password.invalid && (form.controls.password.dirty || form.controls.password.touched)"
        class="error"
      >
        <div *ngIf="form.controls.password.errors.minlength">
          {{ 'signin.password_invalid' | translate }}
        </div>
      </div>
    </label>
    <p class="error-response" *ngIf="error">{{ error }}</p>
    <p class="demo-alert" *ngIf="environment.typeEnv === 'stage'">{{ 'ALERT_REG_DEMO' | translate }}</p>
    <button (click)="register()" [disabled]="form.invalid">{{ 'signin.submit' | translate }}</button>
    <p class="terms">
      <span>{{ 'signin.terms_of_use_1' | translate }}</span>
      <a class="link" routerLink="/privacy-policy" (click)="closeModal()">{{ 'signin.terms_of_use_2' | translate }}</a>
    </p>
  </article>
</ng-template>

<ng-template #signInForm>
  <article [formGroup]="form" (keydown.enter)="signEmail()">
    <label>
      <span>{{ 'signin.email' | translate }}</span>
      <input type="email" placeholder="{{'signin.email_placeholder' | translate}}" formControlName="email">
      <div
        *ngIf="form.controls.email.invalid && (form.controls.email.dirty || form.controls.email.touched)"
        class="error"
      >
        <div *ngIf="form.controls.email.errors.email">
          {{ 'signin.email_invalid' | translate }}
        </div>
      </div>
    </label>
    <label>
      <span>{{ 'signin.password' | translate }}</span>
      <input type="password" placeholder="{{'signin.password_placeholder' | translate}}" formControlName="password">
      <div
        *ngIf="form.controls.password.invalid && (form.controls.password.dirty || form.controls.password.touched)"
        class="error"
      >
        <div *ngIf="form.controls.password.errors.minlength">
          {{ 'signin.password_invalid' | translate }}
        </div>
      </div>
    </label>
    <p class="error-response" *ngIf="error">{{ error }}</p>
    <button (click)="signEmail()" [disabled]="form.invalid">{{ 'signin.signin' | translate }}</button>
    <p>
      <a class="link" (click)="setTabValue('forgot')">{{ 'signin.forgot_password' | translate }}</a>
    </p>
  </article>
</ng-template>

<ng-template #forgotPasswordForm>
  <app-forgot-password (closeModal)="closeModal()"></app-forgot-password>
</ng-template>
